import React from 'react'
import Layout from '../../utils/layout'
import SEO from '../../utils/seo'
import Top from '../../components/Science/ClinicalStudies/Top'
import Blocks from '../../components/Science/ClinicalStudies/Blocks'

export default ({ pageContext: { data } }) => {
  return (
    <Layout
      data={data}
      signUpUrl="https://app.maximustribe.com/registration"
      signUpText="Start online visit"
      signInHide={true}
    >
      <SEO {...data.seo} />
      <Top top={data.top} />
      <Blocks blocks={data.blocks} />
    </Layout>
  )
}
